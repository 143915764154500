import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import { MarkdownViewer } from "../components/Markdown"
import { Section, Container } from "../components/Containers"
import { MapCTA } from "../components/CTA"

const Legal = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
        pathname={location.pathname}
      />

      <div className="legal-page">
        <NuvoImage
          publicId={post.hero.image}
          wrapperClassName="is-hidden-mobile"
        />
        <NuvoImage
          publicId={post.hero.imageMobile}
          wrapperClassName="is-hidden-tablet"
        />
        <Section>
          <Container small>
            <h1 className="has-text-centered">{post.heading}</h1>

            <h3>{post.pdfSection.heading}</h3>

            <div className="voices-query__container voices-query__pdfs grid-even-2">
              {post.pdfSection.pdfs.map((item, i) => (
                <div
                  key={i}
                  className="voices__card"
                  style={{ backgroundColor: "#ffffff" }}>
                  <MarkdownViewer markdown={item.blurb} />
                  <a
                    className="voices__card-btn"
                    href={item.href}
                    title={`View the article`}
                    target="_blank">
                    Read More
                  </a>
                </div>
              ))}
            </div>
          </Container>
        </Section>
        <MapCTA
          image={post.mapCta.image}
          // text={post.mapCta.text}
          button={post.mapCta.button}
        />
      </div>
    </Layout>
  )
}

Legal.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const legalPageQuery = graphql`
  query legalQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        hero {
          image
          imageMobile
        }
        pdfSection {
          heading
          pdfs {
            blurb
            href
          }
        }
        mapCta {
          image
          text
          button {
            buttonText
            appearance
            destination
            href
          }
        }
      }
    }
  }
`

export default Legal
